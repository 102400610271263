import React, { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import "../components/base.css";

const IndexPage = () => {
  useEffect(function() {
    window.location =
      "https://drive.google.com/file/d/1uvghY7m9sVVL3n71k5GtyugfXvyCRJBF/view?usp=sharing";
  }, []);
  return <CircularProgress />;
};

export default IndexPage;
